import * as React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const onSelectAllNoGroupClick = ({
  checked,
  setSelectedRoles,
  availableRoles,
  selectedRoles,
}) => {
  const roles = checked
    ? [
        ...selectedRoles.filter((role) => role.group),
        ...availableRoles.filter((role) => !role.group),
      ]
    : [...selectedRoles.filter((role) => role.group)]

  return setSelectedRoles(roles)
}

const onSelectAllGroupClick = ({
  checked,
  group,
  setSelectedRoles,
  availableRoles,
  selectedRoles,
}) => {
  const roles = checked
    ? [
        ...selectedRoles.filter((role) => !role.group),
        ...availableRoles.filter((role) => role.group === group.name),
      ]
    : [...selectedRoles.filter((role) => !role.group)]

  return setSelectedRoles(roles)
}

const onToggleRow = ({ checked, role, setSelectedRoles, selectedRoles }) => {
  checked
    ? setSelectedRoles([...selectedRoles, role])
    : setSelectedRoles(selectedRoles.filter((r) => r.id !== role.id))
}

export default function RolesTable({
  selectedRoles,
  availableRoles,
  roleGroups,
  setSelectedRoles,
}) {
  return (
    <div>
      <Paper variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {/* <Checkbox
                  color="primary"
                  indeterminate={
                    selectedRoles.length > 0 &&
                    availableRoles.filter((role) => !role.group).length >
                      selectedRoles.filter((role) => !role.group).length
                  }
                  checked={
                    selectedRoles.length > 0 &&
                    availableRoles.filter((role) => !role.group).length ===
                      selectedRoles.filter((role) => !role.group).length
                  }
                  onChange={(_e, checked) =>
                    onSelectAllNoGroupClick({
                      checked,
                      availableRoles,
                      selectedRoles,
                      setSelectedRoles,
                    })
                  }
                /> */}
              </TableCell>
              <TableCell>
                <strong>Role Name</strong>
              </TableCell>
              <TableCell>
                <strong>Description</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Roles that are not part of a group: */}
            {availableRoles
              .filter((role) => !role.group)
              .map((role) => (
                <RoleRow
                  role={role}
                  availableRoles={availableRoles}
                  selectedRoles={selectedRoles}
                  setSelectedRoles={setSelectedRoles}
                />
              ))}
          </TableBody>
        </Table>
      </Paper>
      <br />
      {/* Roles that are part of a group: */}
      {roleGroups.map((group) => (
        <Paper variant="outlined">
          <Table>
            <TableBody>
              <RoleGroup
                group={group}
                availableRoles={availableRoles}
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
              />
            </TableBody>
          </Table>
        </Paper>
      ))}
    </div>
  )
}

function RoleGroup({
  group,
  availableRoles,
  selectedRoles,
  setSelectedRoles,
  subgroup,
}) {
  const [open, setOpen] = React.useState(true)

  const roles = availableRoles
    .filter(
      (role) =>
        (role.group === group.name && !role.subgroup) ||
        role.subgroup === group.name
    )
    .map((role) => (
      <RoleRow
        availableRoles={availableRoles}
        selectedRoles={selectedRoles}
        role={role}
        setSelectedRoles={setSelectedRoles}
      />
    ))

  return (
    <React.Fragment>
      <TableRow
        // sx={{ '& > *': { borderBottom: 'unset' } }}
        style={{
          backgroundColor: subgroup ? '#eeeeee' : '#ffffff',
          cursor: subgroup ? 'pointer' : null,
        }}
        onClick={() => {
          if (subgroup) {
            setOpen(!open)
          }
        }}
      >
        <TableCell padding="checkbox">
          {subgroup ? (
            <IconButton
              aria-label="expand row"
              size="medium"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            null
            // <Checkbox
            //   color="primary"
            //   indeterminate={
            //     selectedRoles.length > 0 &&
            //     availableRoles.filter((role) => role.group === group.name)
            //       .length >
            //       selectedRoles.filter((role) => role.group === group.name)
            //         .length
            //   }
            //   checked={
            //     selectedRoles.length > 0 &&
            //     availableRoles.filter((role) => role.group === group.name)
            //       .length ===
            //     selectedRoles.filter((role) => role.group === group.name).length
            //   }
            //   onChange={(_e, checked) =>
            //     onSelectAllGroupClick({
            //       checked,
            //       group,
            //       availableRoles,
            //       selectedRoles,
            //       setSelectedRoles,
            //     })
            //   }
            // />
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {subgroup ? group.name : <strong>{group.name}</strong>}
        </TableCell>
        <TableCell>
          {subgroup ? group.description : <strong>{group.description}</strong>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
          colSpan={3}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="roles">
              <TableBody style={{ padding: '0px' }}>
                {roles}
                {group.subgroups?.map((subgroup) => (
                  <RoleGroup
                    availableRoles={availableRoles}
                    selectedRoles={selectedRoles}
                    setSelectedRoles={setSelectedRoles}
                    group={subgroup}
                    subgroup={true}
                  />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function RoleRow({ availableRoles, selectedRoles, role, setSelectedRoles }) {
  const checked = selectedRoles.filter((r) => r.id === role.id).length > 0

  return (
    <TableRow
      key={role.id}
      hover
      selected={checked}
      style={{ cursor: 'pointer' }}
      onClick={() =>
        onToggleRow({
          checked: !checked,
          role,
          setSelectedRoles,
          availableRoles,
          selectedRoles,
        })
      }
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={checked}
          onChange={(_e, checked) =>
            onToggleRow({
              checked,
              role,
              setSelectedRoles,
              availableRoles,
              selectedRoles,
            })
          }
        />
      </TableCell>
      <TableCell>{role.name}</TableCell>
      <TableCell>Request Access to {role.desc}</TableCell>
    </TableRow>
  )
}
