import * as React from 'react'
import { Link } from 'react-router-dom'

import Hidden from '@material-ui/core/Hidden'

export default function SideMenu() {
  return (
    <Hidden smUp>
      <Link to="/">Home</Link>
      <Link to="/pending">Pending</Link>
      <Link to="/approvals">Approvals</Link>
      <Link to="/history">History</Link>
      <Link to="/help">Help</Link>
      <Link to="/report">Report</Link>
      <Link to="/account">My Account</Link>
    </Hidden>
  )
}
