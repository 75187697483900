import * as React from 'react'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import Contact from '../Contact'

export default function Warning({ title, message }) {
  return (
    <Alert
      variant="outlined"
      severity="error"
      style={{ backgroundColor: '#ffffff' }}
    >
      <AlertTitle>{title}</AlertTitle>
      {message}
      <br />
      <br />
      <Contact />
    </Alert>
  )
}
