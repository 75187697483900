import * as React from 'react'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

export default function Error({ error, message }) {
  let errorMessage = null

  if (message) {
    errorMessage = message
  } else if (error && error.graphQLErrors) {
    errorMessage =
      (error.graphQLErrors[0]?.name || 'Error') +
      ': ' +
      error.graphQLErrors[0]?.message
  } else {
    return null
  }

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {errorMessage}
    </Alert>
  )
}
