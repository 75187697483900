import * as React from 'react'
import { Page } from 'react-pdf'
import { Document } from 'react-pdf/dist/esm/entry.webpack'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { getUri } from '../utilities'
import useSize from '../hooks/useSize'
import Contact from './Contact'

export default function Help() {
  const [numPages, setNumPages] = React.useState(0)
  const [pageNumber, setPageNumber] = React.useState(1)
  const parentRef = React.useRef(null)
  const { width } = useSize(parentRef)

  const onDocumentLoad = ({ numPages }) => setNumPages(numPages)
  const changePage = (offset) => setPageNumber(pageNumber + offset)
  const previousPage = () => changePage(-1)
  const nextPage = () => changePage(1)

  return (
    <React.Fragment>
      <Paper elevation={0} variant="outlined">
        <Typography variant="h5" style={{ padding: '10px' }}>
          Help
        </Typography>
        <Divider />
        <Typography
          variant="body2"
          color="text"
          component="p"
          style={{ padding: '10px' }}
        >
          Below is a PDF file of the help docs for the Data Access Request web
          application. <br />
          <Contact />
        </Typography>
      </Paper>
      <br />
      <div style={{ textAlign: 'center' }} ref={parentRef}>
        Jump to: &nbsp;
        <Button
          color="primary"
          size="small"
          variant="contained"
          disabled={pageNumber === 4}
          onClick={() => setPageNumber(4)}
        >
          Requester Process
        </Button>
        &nbsp;
        <Button
          color="primary"
          size="small"
          variant="contained"
          disabled={pageNumber === 10}
          onClick={() => setPageNumber(10)}
        >
          Supervisor Process
        </Button>
        &nbsp;
        <Button
          color="primary"
          size="small"
          variant="contained"
          disabled={pageNumber === 13}
          onClick={() => setPageNumber(13)}
        >
          Data Steward Process
        </Button>
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          color="primary"
          variant="text"
          disabled={pageNumber === 1}
          onClick={() => previousPage()}
        >
          &lt; Back
        </Button>
        <Paper style={{ alignContent: 'center' }}>
          <p>{`Page ${pageNumber} of ${numPages}`}</p>
          <Document
            file={getUri({ path: 'DARHelp.pdf' })}
            onLoadSuccess={onDocumentLoad}
          >
            <Page pageNumber={pageNumber} width={width * 0.8} />
          </Document>
        </Paper>
        <Button
          color="primary"
          variant="text"
          disabled={pageNumber === numPages}
          onClick={() => nextPage()}
        >
          Next &gt;
        </Button>
        <br />
      </div>
    </React.Fragment>
  )
}
