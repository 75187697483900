import * as React from 'react'
import * as Sentry from '@sentry/browser'

import Button from '@material-ui/core/Button'

export default class ErrorBoundary extends React.Component {
  state = { error: null }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      this.setState({ error })
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key])
        })
        Sentry.captureException(error)
        // Sentry.showReportDialog()
      })
    }
  }

  render() {
    // console.log(process.env.NODE_ENV)

    if (this.state.error) {
      // render fallback UI
      return (
        <Button onClick={() => Sentry.showReportDialog()}>
          Report feedback
        </Button>
      )
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}
