import Button from '@material-ui/core/Button'

export default function Contact() {
  return (
    <>
      If you need further assistance, please contact the Enterprise Integration
      Services team by e-mailing:{' '}
      <Button
        href="mailto:doit_enterprise_integration_services@mail.colostate.edu"
        variant="outlined"
        size="small"
      >
        doit_enterprise_integration_services@mail.colostate.edu
      </Button>
    </>
  )
}
