import * as React from 'react'
import gql from 'graphql-tag'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Close from '@material-ui/icons/CloseOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import EmailIcon from '@material-ui/icons/Email'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

import Error from '../Error'
import Loading from '../Loading'

const reviewQuery = gql`
  query ReviewQuery($requestId: ID!) {
    request(id: $requestId) {
      id
      role {
        name
        group
        subgroup
      }
      status
      date
      requester {
        name
        username
        csuId
        emailAddress
      }
      supComment
      supervisor {
        name
      }
      dsComment
      dataSteward {
        person {
          name
        }
      }
      vpdApprover {
        name
      }
      vpdComment
      vpdDepartments
    }
  }
`
const updateRequest = gql`
  mutation ($id: ID!, $action: Boolean!, $comment: String!) {
    updateRequest(id: $id, action: $action, comment: $comment) {
      id
      status
      supComment
      dsComment
      vpdComment
    }
  }
`

const reRoute = ({ location, history, requestId }) => {
  if (location.pathname === `/approvals/${requestId}`) {
    history.push('/approvals')
  } else {
    history.push('/')
  }
}

export default function ApprovalDialog({ location, history }) {
  const { id: requestId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [comments, setComments] = React.useState('')
  const { data, error, loading } = useQuery(reviewQuery, {
    variables: { requestId },
  })
  const [updateRequestMutation] = useMutation(updateRequest)
  const clearDialog = () => reRoute({ location, history, requestId })

  if (error) {
    return <Error error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const request = data.request
  const departments = request.vpdDepartments
    ? request.vpdDepartments
        .split(',')
        .map((r) => (
          <Chip
            color="primary"
            key={r}
            label={r}
            style={{ marginRight: '2px' }}
          />
        ))
    : null

  const update = async (action, reqName, roleName) => {
    setIsSubmitted(true)

    await updateRequestMutation({
      variables: { id: requestId, action: action, comment: comments },
      refetchQueries: ['approvals'],
    })

    if (action === true) {
      await enqueueSnackbar(
        `Request from ${reqName} for ${roleName}: APPROVED`,
        {
          variant: 'success',
          action: <Close />,
        }
      )
    } else {
      enqueueSnackbar(`Request from ${reqName} for ${roleName}: DENIED`, {
        variant: 'warning',
        action: <Close />,
      })
    }
    setIsSubmitted(false)
    clearDialog()
  }

  return (
    <Dialog open onClose={clearDialog} onBackdropClick={clearDialog}>
      <DialogTitle>Awaiting Approval</DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>{request.role.name}</TableCell>
            </TableRow>
            {request.role.group && (
              <TableRow>
                <TableCell>Role Group</TableCell>
                <TableCell>{request.role.group}</TableCell>
              </TableRow>
            )}
            {request.role.subgroup && (
              <TableRow>
                <TableCell>Role Sub-group</TableCell>
                <TableCell>{request.role.subgroup}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Request ID</TableCell>
              <TableCell>{request.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Request Date</TableCell>
              <TableCell>{request.date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Requester Name</TableCell>
              <TableCell>{request.requester.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Requester E-mail</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  href={`mailto:${request.requester.emailAddress}`}
                  startIcon={<EmailIcon />}
                >
                  {request.requester.emailAddress}
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Requester Username</TableCell>
              <TableCell>{request.requester.username}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Requester CSU ID</TableCell>
              <TableCell>{request.requester.csuId}</TableCell>
            </TableRow>
            <TableRow></TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{request.status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Supervisor Name</TableCell>
              <TableCell>{request.supervisor.name}</TableCell>
            </TableRow>
            {request.supComment ? (
              <TableRow>
                <TableCell>Supervisor Comment</TableCell>
                <TableCell>{request.supComment}</TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>Data Steward Name</TableCell>
              <TableCell>
                {request.dataSteward && request.dataSteward.person.name}
              </TableCell>
            </TableRow>
            {request.dsComment ? (
              <TableRow>
                <TableCell>DS Comment</TableCell>
                <TableCell>{request.dsComment}</TableCell>
              </TableRow>
            ) : null}
            {request.vpdDepartments ? (
              <TableRow>
                <TableCell>HR Approver Name</TableCell>
                <TableCell>
                  {request.vpdApprover && request.vpdApprover.name}
                </TableCell>
              </TableRow>
            ) : null}
            {request.vpdComment ? (
              <TableRow>
                <TableCell>HR Approver Comment</TableCell>
                <TableCell>{request.vpdComment}</TableCell>
              </TableRow>
            ) : null}
            {request.vpdDepartments ? (
              <TableRow>
                <TableCell>Department Numbers Selected</TableCell>
                <TableCell>{departments}</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        {error && <Error error={error} />}
        <Divider light />
        <p style={{ marginBottom: 5, fontSize: 15 }}>
          A comment is required before you deny a request. Please note that
          these comments are visible to the requester, supervisor, and data
          steward.
        </p>
        <TextField
          required
          variant="outlined"
          label="Comments (255 characters max)"
          onChange={(e, value) => setComments(e.target.value)}
          rows="3"
          multiline
          fullWidth
          inputProps={{ maxLength: 255 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitted}
          style={{ borderRadius: '10px' }}
          onClick={() =>
            update(true, request.requester.name, request.role.name)
          }
        >
          Approve
        </Button>
        &nbsp;
        <Button
          variant="contained"
          disabled={comments === '' || isSubmitted}
          style={{ borderRadius: '10px' }}
          onClick={() =>
            update(false, request.requester.name, request.role.name)
          }
        >
          Deny
        </Button>
      </DialogActions>
    </Dialog>
  )
}
