import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Alert from '@material-ui/lab/Alert'

import Error from './components/Error'

const query = gql`
  query getConfiguration {
    getConfiguration {
      id
      maintenanceMessage
    }
  }
`

export default function MaintenanceMessage() {
  const { loading, error, data } = useQuery(query)

  if (loading) {
    return null
  }
  if (error) {
    return <Error error={error} />
  }

  const configuration = data?.getConfiguration

  if (!configuration.maintenanceMessage) {
    return null
  }

  return (
    <div style={{ paddingBottom: '10px' }}>
      <Alert severity="info"><div dangerouslySetInnerHTML={{__html: configuration.maintenanceMessage}}></div></Alert>
    </div>
  )
}
