import * as React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'

import { query } from '../Home'
import Loading from '../Loading'
import Error from '../Error'

const createUserAgreementMutation = gql`
  mutation($action: Boolean!) {
    createUserAgreement(action: $action) {
      userAgreementFlag
      userAgreementDate
      ferpaDate
      ferpaFlag
    }
  }
`

export default function UserAgreementDialog() {
  const history = useHistory()
  const [submitting, setSubmitting] = React.useState(false)
  const [checked, setChecked] = React.useState(false)

  const [createUserAgreement, { error }] = useMutation(
    createUserAgreementMutation,
    {
      update(cache, { data: { createUserAgreement } }) {
        const agreement = cache.readQuery({ query })

        cache.writeQuery({
          query,
          data: {
            ...agreement,
            agreement: {
              ...createUserAgreement,
            },
          },
        })
      },
    }
  )

  return (
    <Dialog open={true}>
      <DialogTitle>USER AGREEMENT</DialogTitle>
      <Divider light />
      <DialogContent>
        <div>
          <p>
            I understand that data within the ODS is confidential and that the
            access I am requesting is required to perform my job duties and
            responsibilities. I understand that individuals working with
            University records hold a position of trust and must recognize the
            responsibilities of preserving the security and confidentiality of
            the information. I agree that I have read and understand the I.T.
            Security and Acceptable Use policies of the University and will
            abide by those policies. I understand that misuse will result in the
            revocation of my access. If I terminate employment with the
            University, and/or if I terminate employment with the department or
            change job duties within the department through which I am now
            requesting access, the access granted to me will terminate. If I
            require access in a new department or position, I must repeat this
            process.
          </p>
          <Divider light />
          <p>
            Please check this box to indicate you have read the above notice and
            agree to it. You must check the box and click submit to continue.
          </p>
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(event, checked) => setChecked(checked)}
          />
          <span>I agree</span>
          <Divider light />
          <br />
          {error && <Error error={error} />}
          {submitting && <Loading />}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!checked || submitting}
          onClick={async () => {
            try {
              setSubmitting(true)
              await createUserAgreement({
                variables: { action: checked },
              })
              history.push('/')
            } catch (err) {
              setSubmitting(false)
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
