import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Error from './components/Error'
import Loading from './components/Loading'

const query = gql`
  query getConfiguration {
    getConfiguration {
      id
      features
    }
  }
`

export default function Feature({ name, children }) {
  const { loading, error, data } = useQuery(query)

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const configuration = data?.getConfiguration

  return configuration.features.includes(name) ? children : null
}
