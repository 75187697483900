import { ApolloClient, InMemoryCache } from '@apollo/client'

import { getUri } from './utilities'

const client = new ApolloClient({
  uri: getUri({ path: 'graphql' }),
  // uri: "/graphql",
  credentials: 'include',
  cache: new InMemoryCache(),
})

export default client
