import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Error from '../Error'
import Loading from '../Loading'
import ApprovalTable from './ApprovalTable'

export const query = gql`
  query approvals {
    requestsForSupervisor {
      id
      requester {
        name
      }
      role {
        name
        group
        subgroup
      }
      date
      awaiting
    }
    requestsForDataSteward {
      id
      requester {
        name
      }
      role {
        name
        group
        subgroup
      }
      date
      awaiting
    }
    requestsForVpdApprover {
      id
      requester {
        name
      }
      role {
        name
        group
        subgroup
      }
      date
      awaiting
    }
  }
`

export default function Approvals() {
  const { loading, error, data } = useQuery(query, {
    fetchPolicy: 'network-only',
  })

  if (error) {
    return <Error error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const {
    requestsForSupervisor,
    requestsForDataSteward,
    requestsForVpdApprover,
  } = data

  return (
    <>
      <Paper elevation={0} variant="outlined">
        <Typography variant="h5" style={{ padding: '10px' }}>
          Awaiting your Approval
        </Typography>
        <Divider />
        <p style={{ padding: '10px' }}>
          In order to approve or deny a request, click on the{' '}
          <Button
            color="primary"
            size="small"
            variant="contained"
            style={{ borderRadius: '10px' }}
          >
            Review
          </Button>{' '}
          button for the request you'd like to take action on below.
        </p>
      </Paper>
      <br />
      {requestsForSupervisor.filter((req) => req.awaiting === 'SUPERVISOR') <=
        0 &&
        requestsForDataSteward.filter(
          (req) => req.awaiting === 'DATASTEWARD'
        ) <= 0 &&
        requestsForVpdApprover.filter((req) => req.awaiting === 'VPDACCESS') <=
          0 && (
          <Alert severity="info">
            There are no requests awaiting your approval.
          </Alert>
        )}
      <ApprovalTable
        requests={requestsForSupervisor}
        title="Pending Requests for Your Role as a Supervisor"
        approvalRole="SUPERVISOR"
      />
      <ApprovalTable
        requests={requestsForDataSteward}
        title="Pending Requests for Your Role as a Data Steward"
        approvalRole="DATASTEWARD"
      />
      <ApprovalTable
        requests={requestsForVpdApprover}
        title="Pending Requests for Your Role as a VPD Approver"
        approvalRole="VPDACCESS"
      />
    </>
  )
}
