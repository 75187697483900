import * as React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import idx from 'idx'

import Button from '@material-ui/core/Button'

const cancelRequestMutation = gql`
  mutation cancelRequest($id: ID!) {
    cancelRequest(id: $id) {
      id
    }
  }
`

export default function CancelRequestButton({ id, refetch }) {
  const [cancelRequest] = useMutation(cancelRequestMutation)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={async (event) => {
        event.stopPropagation()

        if (window.confirm('Are you sure you want to cancel this request?')) {
          try {
            await cancelRequest({
              variables: { id },
            })

            enqueueSnackbar(`Request ID ${id} has been cancelled.`, {
              variant: 'success',
            })

            refetch()
          } catch (error) {
            const errorMessage =
              idx(error, (_) => _.graphQLErrors[0].message) || error.message

            enqueueSnackbar(
              `There was an error while cancelling request ID ${id}: ${errorMessage}`,
              {
                variant: 'error',
              }
            )
          }
        }
      }}
    >
      Cancel Request
    </Button>
  )
}
