import * as React from 'react'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

export default function SignOut() {
  return (
    <Paper elevation={0} variant="outlined" style={{ padding: '10px' }}>
      <Typography variant="h5">Close Browser to Complete Logout</Typography>
      <p>
        You need to close your browser in order to terminate your session and
        completely log out.
      </p>
      <p>
        To protect your privacy, completely exit your web browser when finished.
        Your session will remain in effect until you completely close all
        instances of your browser.
      </p>

      <p>
        Go back to{' '}
        <a href="https://aar.is.colostate.edu/">
          Administrative Applications and Resources{' '}
        </a>
      </p>
    </Paper>
  )
}
