import * as React from 'react'
import { Route, useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import MUIDataTable from 'mui-datatables'

import ApprovalDialog from './ApprovalDialog'

export default function ApprovalTable({ requests, approvalRole, title }) {
  let history = useHistory()

  const pending = requests.filter(
    (request) => request.awaiting === approvalRole
  )

  if (pending.length <= 0) {
    return null
  }

  const columns = [
    { name: 'Request ID', options: { filter: false, sort: true } },
    { name: 'Requester Name', options: { filter: false, sort: true } },
    { name: 'Role Name', options: { filter: true, sort: true } },
    { name: 'Role Group', options: { filter: true, sort: true } },
    { name: 'Role Sub-group', options: { filter: true, sort: true } },
    { name: 'Date', options: { filter: false, sort: true } },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (id) => (
          <Button
            color="primary"
            variant="contained"
            style={{ borderRadius: '10px' }}
            onClick={() => history.push(`/approvals/${id}`)}
          >
            Review
          </Button>
        ),
      },
    },
  ]

  return (
    <>
      <Paper elevation={0} variant="outlined">
        <MUIDataTable
          title={title}
          data={pending
            .map((request) => [
              request.id,
              request.requester?.name,
              request.role?.name,
              request.role?.group,
              request.role?.subgroup,
              request.date,
              request.id,
            ])
            .reverse()}
          columns={columns}
          options={{
            filterType: 'dropdown',
            selectableRows: 'none',
            download: false,
            print: false,
            viewColumns: false,
            elevation: 0,
            onRowClick: ([id]) => history.push(`/approvals/${id}`),
          }}
        />
      </Paper>

      <Route exact path="/approvals/:id" component={ApprovalDialog} />
    </>
  )
}
