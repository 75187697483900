import { useState, useCallback, useEffect } from 'react'

export default function useSize(elementRef) {
  const [{ width, height }, setSize] = useState({ width: null, height: null })

  const updateSize = useCallback(() => {
    if (elementRef?.current) {
      const { width, height } = elementRef.current.getBoundingClientRect()

      setSize({ width, height })
    }
  }, [elementRef])

  useEffect(() => {
    updateSize()

    window.addEventListener('resize', updateSize)

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [updateSize])

  return { width, height }
}
