import * as React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import Button from '@material-ui/core/Button'

import FerpaAgreementDialog from '../forms/FerpaAgreementDialog'
import VpdTablesDialog from '../forms/VpdTablesDialog'

const createRequestsMutation = gql`
  mutation($csuRoleIds: [ID!], $vpdDepartments: [String!]) {
    createRequests(csuRoleIds: $csuRoleIds, vpdDepartments: $vpdDepartments) {
      id
    }
  }
`

export default function SubmitButton({
  selectedRoles,
  agreement,
  setIsSubmittedDialogOpen,
  setSelectedRoles,
}) {
  const [isFerpaDialogOpen, setIsFerpaDialogOpen] = React.useState(false)
  const [isVpdDialogOpen, setIsVpdDialogOpen] = React.useState(false)
  // const [isSubmittedDialogOpen, setIsSubmittedDialogOpen] = React.useState(
  //   false
  // )

  const [createRequests] = useMutation(createRequestsMutation)

  const selectedIds = selectedRoles.map((i) => i.id)
  const selectedNames = selectedRoles.map((i) => i.name)

  const createRequestsHelper = async (vpdDepartments) => {
    const response = await createRequests({
      variables: { csuRoleIds: selectedIds, vpdDepartments },
      refetchQueries: ['RequesterQuery'],
    })

    // TODO: add optimistic updates
    return response
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        disabled={selectedRoles.length < 1}
        onClick={async () => {
          // Check if requester has FERPA flag accepted and requesting access for Student roles
          if (
            !agreement ||
            (agreement.ferpaFlag !== '1' &&
              [
                'Graduate Student Data',
                'Student Data',
                'General Directory',
                'Accounts Receivable',
              ].some((r) => selectedNames.indexOf(r) >= 0))
          ) {
            if (
              ['Human Resources', 'CSU Foundation Data '].some(
                (r) => selectedNames.indexOf(r) >= 0
              )
            ) {
              setIsVpdDialogOpen(true)
            }
            setIsFerpaDialogOpen(true)
          } else if (
            ['Human Resources', 'CSU Foundation Data '].some(
              (r) => selectedNames.indexOf(r) >= 0
            )
          ) {
            setIsVpdDialogOpen(true)
          } else {
            createRequestsHelper()
            setIsSubmittedDialogOpen(true)
          }
        }}
      >
        Submit
      </Button>

      {isVpdDialogOpen && (
        <VpdTablesDialog
          isFerpaDialogOpen={isFerpaDialogOpen}
          setIsFerpaDialogOpen={setIsFerpaDialogOpen}
          createRequests={createRequestsHelper}
          setIsVpdDialogOpen={setIsVpdDialogOpen}
          setIsSubmittedDialogOpen={setIsSubmittedDialogOpen}
        />
      )}
      {isFerpaDialogOpen && !isVpdDialogOpen && (
        <FerpaAgreementDialog
          createRequests={createRequestsHelper}
          closeDialog={() => setIsFerpaDialogOpen(false)}
          setIsSubmittedDialogOpen={setIsSubmittedDialogOpen}
          departmentNumbers={[]}
        />
      )}
      {/* {isSubmittedDialogOpen && (
        <SubmittedDialog
          onCloseDialog={() => setIsSubmittedDialogOpen(false)}
          roleNames={selectedNames}
          setSelectedRoles={setSelectedRoles}
        />
      )} */}
    </>
  )
}
