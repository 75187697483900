import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid'

import LoginOption from './LoginOption'

const query = gql`
  query getConfiguration {
    getConfiguration {
      logins {
        title
        description
        imageUrl
        buttonText
        buttonColor
        loginUrl
      }
    }
  }
`

export default function Login() {
  const { loading, /*error,*/ data } = useQuery(query)

  if (loading || !data) return null
  // if (error) return `Error!: ${error}`;

  const { logins } = data.getConfiguration

  const sm = 12 / 2 // loginCount

  return (
    <div>
      <Grid container spacing={3}>
        {logins.map((login) => (
          <Grid item xs={12} sm={sm}>
            <LoginOption {...login} />
          </Grid>
        ))}
      </Grid>
      <br />
    </div>
  )
}
