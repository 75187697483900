import * as React from 'react'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'

const logoutMutation = gql`
  mutation {
    authLogout
  }
`

export default function SignOutButton({ children }) {
  const history = useHistory()
  const client = useApolloClient()

  const signOut = async () => {
    try {
      await client.mutate({
        mutation: logoutMutation,
      })
      client.resetStore()
      history.push('/signout')
    } catch (e) {}
  }

  return (
    <Button color="primary" variant="contained" onClick={signOut}>
      {children}
    </Button>
  )
}
