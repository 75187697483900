import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
// import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Error from '../Error'
import Loading from '../Loading'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
}))

const query = gql`
  query {
    auth {
      username
    }
    # getConfiguration {
    #   id
    #   databases
    # }
  }
`

const resetPasswordMutation = gql`
  mutation(
    $environmentClassification: EnvironmentClassification!
    $password: String!
  ) {
    resetPassword(
      environmentClassification: $environmentClassification
      password: $password
    )
  }
`

const environmentClassifications = [
  {
    value: 'PRODUCTION',
    name: 'Production ODS',
  },
  {
    value: 'NON_PRODUCTION',
    name: 'Non-Production ODS',
  },
]

export default function ResetPassword() {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [
    environmentClassification,
    setEnvironmentClassification,
  ] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  const { loading, error, data } = useQuery(query, {
    fetchPolicy: 'network-only',
  })
  const [resetPassword, { error: error2 }] = useMutation(resetPasswordMutation)

  const resetFields = () => {
    setEnvironmentClassification('')
    setPassword('')
    setConfirmPassword('')
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()

      await resetPassword({
        variables: { environmentClassification, password },
      })

      resetFields()

      const name = environmentClassifications.find(
        (entry) => entry.value === environmentClassification
      ).name

      enqueueSnackbar(`Your password for ${name} has been changed.`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
    } catch (e) {
      enqueueSnackbar(`An error occured.`, {
        variant: 'error',
      })
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  const disabled =
    environmentClassification === '' ||
    password === '' ||
    confirmPassword === '' ||
    password !== confirmPassword

  return (
    <Paper elevation={0} variant="outlined">
      <Typography variant="h5" style={{ padding: '10px' }}>
        Reset ODS Password
      </Typography>
      <Divider />
      <p style={{ padding: '10px' }}>
        Entering a password and submitting this form will update your password
        in the Operational Data Store (ODS) for the{' '}
        <Chip label={data.auth?.username} variant="outlined" size="small" />{' '}
        user account. This form will NOT modify your eID/NetID password. To
        change the password for a departmental web user account, you can use
        SQLPlus or Oracle SQL Developer.
        <br />
        <strong>
          If you have forgotten an ODS web user account password, please contact
          Information Systems for assistance by opening a help ticket in{' '}
          <a
            href="https://colostate.teamdynamix.com/TDClient/Requests/TicketRequests/TicketForm.aspx?ID=c0ud7kaZV6U_"
            target="_blank"
            rel="noreferrer"
          >
            TeamDynamix
          </a>
          .
        </strong>
        <h3>Password Rules:</h3>
        <ul>
          <li>Your password must be 15 to 30 characters long.</li>
          <li>
            Your username, first name, or last name must not be part of your
            password.
          </li>
          <li>
            Certain special characters must not be used: @ $ &amp; " ( ) ' ; = #
            * blank_space &lt; &gt;
          </li>
        </ul>
      </p>
      <Divider />
      <Error error={error2} />
      <div style={{ padding: '10px' }}>
        <form onSubmit={handleSubmit}>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel>ODS Environment</InputLabel>
            <Select
              fullWidth
              value={environmentClassification}
              onChange={(event) =>
                setEnvironmentClassification(event.target.value)
              }
              displayEmpty
            >
              {environmentClassifications.map((entry) => (
                <MenuItem
                  key={`environment_classification_${entry.value}`}
                  value={entry.value}
                >
                  {entry.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              type="password"
              label="New Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <br />
          <br />
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              type="password"
              label="Confirm Password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormControl>
          <br />
          {error && <Error error={error} />}
          <br />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={disabled}
          >
            Reset Password
          </Button>
        </form>
      </div>
    </Paper>
  )
}
