import * as React from 'react'

import Button from '@material-ui/core/Button'
import Copy from '@material-ui/icons/FileCopy'

import CopyRolesPopover from './CopyRolesPopover'

export default function CopyRolesButton({ setSelectedRoles, availableRoles }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <Button
        style={{ margin: 7, float: 'right' }}
        variant="contained"
        size="small"
        color="primary"
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
          setIsOpen(true)
        }}
      >
        <Copy />
        &nbsp; Copy Roles
      </Button>

      <CopyRolesPopover
        isOpen={isOpen}
        anchorEl={anchorEl}
        onClose={() => setIsOpen(false)}
        setSelectedRoles={setSelectedRoles}
        availableRoles={availableRoles}
      />
    </>
  )
}
