import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Details from './Details'
import Error from '../Error'
import Loading from '../Loading'

const query = gql`
  query SupQuery($filter: Boolean!) {
    requestsForSupervisor(filter: $filter) {
      id
      role {
        name
      }
      status
      awaiting
      date
      requester {
        name
      }
      dataSteward {
        person {
          name
        }
      }
    }
  }
`

export default function HistoryBySupervisor({ filter }) {
  const { loading, error, data } = useQuery(query, {
    variables: { filter },
  })

  if (error) return <Error error={error} />
  if (loading) return <Loading />

  const filtered = (data.requestsForSupervisor || []).filter(
    (req) => req.awaiting === 'NONE'
  )

  return <Details type="Supervisor" requests={filtered} />
}
