import * as React from 'react'
import { Link } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import HistoryDetails from './HistoryDetails'

export default function History() {
  return (
    <React.Fragment>
      <Paper elevation={0} variant="outlined">
        <Typography variant="h5" style={{ padding: '10px' }}>
          History of Completed Requests
        </Typography>
        <Divider />
        <div style={{ padding: '10px' }}>
          <Typography variant="body2" color="text" component="p">
            This page shows all of the requests that have been completed as per
            your role in a request. Requests that you requested will listed be
            under the Requester table. Requests where you are listed as the
            Supervisor will be under the Superviser table. Finally, requests
            where you are listed as the Data Steward will be under the Data
            Steward table.
          </Typography>
          <br />
          <Typography variant="body2" color="text" component="p">
            A request will have the status of completed if it is either{' '}
            <i>DS Approved, DS Denied, or Supervisor Denied</i>. If a request
            does not have one of the previously listed statuses, it is still{' '}
            <Link to="/pending">Pending</Link> and will not appear on this page.
          </Typography>

          <br />
          <Typography variant="body2" color="text" component="p">
            You can click on each request to show more details about the request
            such as the comments and deny/approve date.
          </Typography>
        </div>
      </Paper>
      <br />
      <div>
        <HistoryDetails role="Requester" />
        <HistoryDetails role="Supervisor" />
        <HistoryDetails role="Data Steward" />
        <HistoryDetails role="HR Approver" />
      </div>
    </React.Fragment>
  )
}
