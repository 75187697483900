import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Detailed from './Details'
import Error from '../Error'
import Loading from '../Loading'

const query = gql`
  query RequesterQuery {
    requests {
      uaCsuId
      date
      id
      isPending
      status
      requester {
        name
      }
      role {
        name
      }
    }
  }
`

export default function HistoryByRequester() {
  const { loading, error, data } = useQuery(query)

  if (error) return <Error error={error} />
  if (loading) return <Loading />

  const filtered = (data.requests || []).filter(
    (req) => req.isPending === false
  )

  return <Detailed type="Requester" requests={filtered} />
}
