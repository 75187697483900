import React from 'react'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    
    section1: {
      textAlign: 'center',
      margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    },
  })
)

export default function LoginOption({
  title,
  description,
  imageUrl,
  buttonText,
  buttonColor,
  loginUrl,
}) {
  const classes = useStyles()

  return (
    <Paper style={{ padding: '10px' }}>
      <div className={classes.root}>
        <div className={classes.section1}>
          <Grid container alignItems="center">
            <Grid item xs>
              {imageUrl && <img src={imageUrl} width={200} height={200} alt={title} />}

              <Typography gutterBottom variant="h5" noWrap>
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Typography color="textSecondary">{description}</Typography>
        </div>
        <br />
        <Button variant="outlined" color="default" fullWidth href={loginUrl}>
          {buttonText}
        </Button>
      </div>
    </Paper>
  )
}
