import React from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
// import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import './App.css'

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#FEFDF6',
    // fontFamily: 'prox-regular',
    // fontFamily: 'factoria-book',
    position: 'relative',
    minHeight: '100vh',
    '& a': {
      color: '#000000',
    },
  },
  rootWrap: {
    // paddingBottom: '7.5rem',
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  hide: {
    display: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: '10px',
  },
  footer: {
    color: '#448356',
    position: 'absolute',
    bottom: '0',
    // height: '4.5rem',
    width: '100%',
    padding: '20px',
    backgroundColor: '#1E4D2B',
  },
  footerLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'right',
    '& a': {
      color: '#448356',
      marginLeft: '10px',
    },
  },
}))

export default function Layout(props) {
  const classes = useStyles()

  const {
    headerTitle,
    main,
    topMenu,
    appBarLogoUrl,
    // primaryColor,
    // secondaryColor,
  } = props

  return (
    <div>
      <Helmet
        title={props.headerTitle}
        meta={[
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, user-scalable=no',
          },
        ]}
      />
      <div className={classes.root}>
        <div className={classes.rootWrap}>
          <CssBaseline />
          <AppBar position="fixed">
            <Container>
              <Toolbar>
                <Typography variant="h6" noWrap className={classes.title}>
                  <Link
                    to="/"
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    {headerTitle}
                  </Link>
                </Typography>
                <img
                  src={appBarLogoUrl}
                  height={40}
                  className="App-logo"
                  alt="CSU logo"
                />
              </Toolbar>
            </Container>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.drawerHeader} />
            <Container style={{ backgroundColor: '' }}>
              <div style={{ textAlign: 'center' }}>{topMenu}</div>
              <br />
              {main}
            </Container>
          </main>
          {/* <br /> */}
        </div>
        {/* <footer className={classes.footer}>
          <Container>
            <Typography variant="body1">
              <Grid container spacing={3}>
                <Grid item xs={6} className={classes.copyright}>
                  &copy; {new Date().getFullYear()} Colorado State University
                </Grid>
                <Grid item xs={6} className={classes.footerLinks}>
                  <a
                    href="http://www.colostate.edu/info-contact.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact CSU
                  </a>
                  <a
                    href="http://www.colostate.edu/info-disclaimer.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Disclaimer
                  </a>
                  <a
                    href="http://www.colostate.edu/info-equalop.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Equal Opportunity
                  </a>
                  <a
                    href="http://www.colostate.edu/info-privacy.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Statement
                  </a>
                </Grid>
              </Grid>
            </Typography>
          </Container>
        </footer> */}
      </div>
    </div>
  )
}
