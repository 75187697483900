import * as React from 'react'
import { useHistory } from 'react-router-dom'

import AccordionDetails from '@material-ui/core/AccordionDetails'
import MUIDataTable from 'mui-datatables'

const columns = [
  { name: 'Request ID', options: { filter: false, sort: false } },
  { name: 'Requester', options: { filter: false, sort: true } },
  { name: 'Date', options: { filter: false, sort: true } },
  { name: 'Role Name', options: { filter: true, sort: true } },
  { name: 'Status', options: { filter: true, sort: true } },
]

export default function Details({ type, requests }) {
  let history = useHistory()

  return requests.length > 0 ? (
    <MUIDataTable
      data={requests
        .map((req) => [
          req.id,
          req.requester?.name,
          req.date,
          req.role.name,
          req.status,
        ])
        .reverse()}
      columns={columns}
      options={{
        filterType: 'dropdown',
        // responsive: 'scrollMaxHeight',
        // responsive: 'standard',
        selectableRows: 'none',
        download: true,
        downloadOptions: { filename: `${type}-History.csv`, separator: ',' },
        print: false,
        elevation: 0,
        onRowClick: (rowIndex) => history.push(`/history/${rowIndex[0]}`),
      }}
    />
  ) : (
    <AccordionDetails>
      <h4>No Request History</h4>
    </AccordionDetails>
  )
}
