import * as React from 'react'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'

const query = gql`
  query rolesByUser($username: String!) {
    rolesByUser(username: $username) {
      id
    }
  }
`

const checkRoles = ({ data, availableRoles }) => {
  const roleIdsByUser = data.rolesByUser.map((role) => role.id)
  const roles = availableRoles.filter(
    (availableRole) => roleIdsByUser.indexOf(availableRole.id) > -1
  )

  return roles
}

export default function CopyRolesPopover({
  isOpen,
  anchorEl,
  onClose,
  setSelectedRoles,
  availableRoles,
}) {
  const [username, setUsername] = React.useState('')
  const { enqueueSnackbar } = useSnackbar()
  const client = useApolloClient()

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const { data } = await client.query({
        query,
        variables: { username },
      })
      const copiedUserRoles = checkRoles({ data, availableRoles })

      if (data && copiedUserRoles.length >= 1) {
        // set selected roles as fetched users' granted roles
        setSelectedRoles(copiedUserRoles)

        enqueueSnackbar(
          `Success! Granted roles for ${username} copied. ${copiedUserRoles
            .map((i) => i.name)
            .join(
              ', '
            )} are now checked. Click SUBMIT to request access to the same roles. `,
          { variant: 'success' }
        )
      } else {
        enqueueSnackbar(
          'The user you entered do not have granted access to the roles listed above or the roles are currently pending. Please check that the username is correct or try another one.',
          { variant: 'warning' }
        )
      }
    } catch (err) {
      enqueueSnackbar('An unexpected error occured.', { variant: 'error' })
    }

    onClose()
  }
  return (
    <Popover
      id="render-props-popover"
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <Card>
        <form onSubmit={onSubmit}>
          <CardContent>
            <p style={{ fontSize: 15 }}>
              Type in the username of the person to copy their granted roles
            </p>
            <TextField
              id="standard-name"
              label="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
              style={{ margin: 0 }}
            />
            <Button
              size="small"
              color="primary"
              variant="contained"
              type="submit"
              style={{ marginTop: 5, marginBottom: 10, float: 'right' }}
            >
              Copy roles
            </Button>
          </CardContent>
        </form>
      </Card>
    </Popover>
  )
}
