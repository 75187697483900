import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import Loading from '../Loading'
import FerpaAgreementDialog from '../forms/FerpaAgreementDialog'

const query = gql`
  query allDepartments {
    allDepartments {
      name
      number
    }
  }
`

export default function VpdTablesDialog({
  setIsSubmittedDialogOpen,
  setIsVpdDialogOpen,
  setIsFerpaDialogOpen,
  createRequests,
  isFerpaDialogOpen,
}) {
  const [submitting, setSubmitting] = React.useState(false)
  const [departmentNumber, setDepartmentNumber] = React.useState([])
  const [checked, setChecked] = React.useState(false)
  const { data, loading } = useQuery(query)

  const handleChange = (value) => {
    const departmentNumbers = value.map((entry) => entry.number)
    setDepartmentNumber(departmentNumbers)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Dialog
        open={true}
        onClose={() => setIsVpdDialogOpen(false)}
        onBackdropClick={() => setIsVpdDialogOpen(false)}
      >
        <DialogTitle>
          Departments That Require HR/CSU Foundation Access
        </DialogTitle>
        <Divider light />
        <DialogContent>
          <div>
            <p>
              Since you have requested Human Resources Data and/or CSU
              Foundation Data, please select the department(s) that you need
              access to from the dropdown list. You can enter a department name
              or number.
            </p>
          </div>
          <div>
            {!checked && (
              <Autocomplete
                multiple
                id="filterSelectedOptions"
                options={data.allDepartments}
                getOptionLabel={(option) => `${option.name} (${option.number})`}
                filterSelectedOptions
                disabled={checked}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Type in Department Name or Number"
                    placeholder="Departments"
                    fullWidth
                  />
                )}
                onChange={(e, value) => handleChange(value)}
              />
            )}
            <Divider light />
            <Checkbox
              color="primary"
              checked={checked}
              onChange={(event, checked) => {
                setChecked(checked)
                setDepartmentNumber([])
              }}
            />
            <span>I need campus wide access</span>
            <Divider light />
            <br />
            {submitting && <Loading />}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={submitting}
            onClick={() => setIsVpdDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={(departmentNumber.length === 0 && !checked) || submitting}
            onClick={async () => {
              try {
                setSubmitting(true)

                if (checked) {
                  await createRequests(['Campus Wide Access'])
                } else {
                  await createRequests(departmentNumber)
                }

                setIsVpdDialogOpen(false)
                setIsSubmittedDialogOpen(true)
              } catch (err) {
                console.log(err)
                setSubmitting(false)
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {isFerpaDialogOpen && (
        <FerpaAgreementDialog
          closeDialog={() => setIsFerpaDialogOpen(false)}
          setIsSubmittedDialogOpen={setIsSubmittedDialogOpen}
          setIsVpdDialogOpen={setIsVpdDialogOpen}
          departmentNumbers={departmentNumber}
        />
      )}
    </>
  )
}
