import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MUIDataTable from 'mui-datatables'

import Error from './Error'
import Loading from './Loading'

const query = gql`
  query {
    usersGrantedByMe {
      name
      roles {
        name
        id
      }
    }
    rolesGrantedByMe {
      id
      name
      myUsers {
        ename
        name
      }
    }
  }
`

const columns = [
  { name: 'Requester Name', options: { filter: false, sort: false } },
  { name: 'Roles Accessed', options: { filter: true, sort: false } },
]

export default function Report() {
  const { loading, error, data } = useQuery(query)

  if (error) return <Error error={error} />
  if (loading) return <Loading />

  const { usersGrantedByMe, rolesGrantedByMe } = data

  return (
    <div>
      <Paper elevation={0} variant="outlined" style={{ padding: '10px' }}>
        <Typography variant="h5">Report</Typography>
        <p>
          The Reports section has two reports that identify which employees in
          your department have access to which roles.
          <br />
          <br />
          <strong>Users by Role</strong> – a report that expands or contracts by
          Role Name that shows which employees in your department have access to
          each ODS Role
          <br />
          <strong>Roles by User</strong> – a table report that displays Employee
          Name and ODS Roles that employee has access to.
        </p>
      </Paper>
      <br />
      <Typography variant="h6">Users by Role</Typography>
      {rolesGrantedByMe.map((r) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {r.name}
          </AccordionSummary>
          <Divider light />
          <AccordionDetails>
            {r.myUsers.length > 0 ? (
              <div>
                {r.myUsers.map((u) => (
                  <Chip
                    color="primary"
                    key={u.csuId}
                    label={u.name}
                    style={{ marginRight: '4px' }}
                  />
                ))}
              </div>
            ) : (
              'There are no users under your stewardship with access to this role'
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      <br />
      <Typography variant="h6">Roles by User</Typography>
      {usersGrantedByMe.length > 0 ? (
        <MUIDataTable
          data={usersGrantedByMe.map((req) => [
            req.name,
            req.roles.map((r) => (
              <Chip
                color="primary"
                key={r.id}
                label={r.name}
                style={{ margin: '4px' }}
              />
            )),
          ])}
          columns={columns}
          options={{
            filterType: 'dropdown',
            responsive: 'scroll',
            selectableRows: false,
            download: false,
            print: false,
            filter: false,
            viewColumns: false,
          }}
        />
      ) : (
        'There are no users under your stewardship that has access to ODS'
      )}
      <br />
    </div>
  )
}
