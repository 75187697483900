import * as React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

export default function SubmittedDialog({
  onCloseDialog,
  roleNames,
  setSelectedRoles,
}) {
  return (
    <Dialog open={true} onClose={onCloseDialog}>
      <DialogTitle>Request Submitted</DialogTitle>
      <Divider light />
      <DialogContent>
        <p>
          Your request has been submitted and your Supervisor has been notified
          about your request. You can check the status of your request under the
          Pending tab.
        </p>
        <Divider light />
        <div>
          {['Human Resources', 'CSU Foundation Data '].some(
            (r) => roleNames.indexOf(r) >= 0
          ) ? (
            <p>
              The steps in getting access to ODS for your request are outlined
              below. For HR and CSU Foundation Data requests after the HR
              approval <i>(Step 4)</i>, you will receive an email on how to
              access the data that you requested. For rest of your requests
              after the Data Steward's approval <i>(Step 3)</i>, you will
              receive an email on how to access the data that you requested.
            </p>
          ) : (
            <p>
              The steps in getting access to ODS for your request are outlined
              below. After the Data Steward's approval <i>(Step 3)</i>, you will
              receive an email on how to access the data that you requested.
            </p>
          )}
          <Stepper>
            <Step orientation="horizontal" completed>
              <StepLabel>1. Request Submitted</StepLabel>
            </Step>
            <Step orientation="horizontal">
              <StepLabel>2. Supervisor Approval</StepLabel>
            </Step>
            <Step orientation="horizontal">
              <StepLabel>3. Data Steward Approval</StepLabel>
            </Step>
            {['Human Resources', 'CSU Foundation Data '].some(
              (r) => roleNames.indexOf(r) >= 0
            ) ? (
              <Step orientation="horizontal">
                <StepLabel>4. HR Approval</StepLabel>{' '}
              </Step>
            ) : null}
          </Stepper>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            if (typeof setSelectedRoles === 'function') {
              setSelectedRoles([])
            }
            onCloseDialog()
          }}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  )
}
