import * as React from 'react'
import { Route } from 'react-router-dom'

import RequestDetails from './components/history/RequestDetails'
// import RequestWorkflow from './components/history/RequestWorkflow'

import Home from './components/Home'
import Pending from './components/Pending'
import Approvals from './components/approval/Approvals'
import Report from './components/Report'
import HistoryPage from './components/history/History'
import SignOut from './components/SignOut'
import MyAccount from './components/account/MyAccount'
import Help from './components/Help'

import FerpaAgreementDialog from './components/forms/FerpaAgreementDialog'
import UserAgreementDialog from './components/forms/UserAgreementDialog'

import AdminRequests from './components/admin/Requests'

export default function Routes() {
  return (
    <>
      <Route exact path="/" component={Home} />

      <Route path="/pending" component={Pending} />
      <Route path="/pending/:id" component={RequestDetails} />

      <Route path="/approvals" component={Approvals} />

      <Route path="/history" component={HistoryPage} />
      <Route exact path="/history/:id" component={RequestDetails} />
      {/* <Route path="/workflow/:id" component={RequestWorkflow} /> */}

      <Route exact path="/signout" component={SignOut} />
      <Route exact path="/help" component={Help} />
      <Route exact path="/report" component={Report} />
      <Route exact path="/account" component={MyAccount} />

      <Route path="/admin" component={AdminRequests} />
      <Route path="/admin/request/:id" component={RequestDetails} />

      <Route
        exact
        path="/forms/ferpa-agreement"
        component={FerpaAgreementDialog}
      />
      <Route
        exact
        path="/forms/user-agreement"
        component={UserAgreementDialog}
      />
    </>
  )
}
