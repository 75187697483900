import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useHistory, Link } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import Error from './Error'
import CancelRequestButton from './CancelRequestButton'
import Loading from './Loading'

export const progressQuery = gql`
  query RequesterQuery {
    requests {
      uaCsuId
      date
      id
      isPending
      status

      role {
        name
        group
        subgroup
      }
    }
  }
`

const getStatus = (status) =>
  status === 'Awaiting DS Action'
    ? 'Awaiting Data Steward Action'
    : 'Awaiting Supervisor Action'

export default function Pending() {
  let history = useHistory()
  const { loading, error, data, refetch } = useQuery(progressQuery)

  if (error) return <Error error={error} />
  if (loading) return <Loading />

  const pendingRequests = (data.requests || []).filter((r) => r.isPending)

  return (
    <div>
      <Paper elevation={0} variant="outlined">
        <Typography variant="h5" style={{ padding: '10px' }}>
          Pending Active Requests
        </Typography>
        <Divider />
        <p style={{ padding: '10px' }}>
          Requests show under this <i>Pending</i> tab are only requests that are
          either <i>Awaiting Supervisor Action</i> or{' '}
          <i>Awaiting Data Steward Action</i>. If the requests you made are not
          shown here, it means that your request(s) have been approved/denied by
          the Data Steward or denied by the Supervisor. Please look at the{' '}
          <Link to="/history">History</Link> tab and click on the request to
          show more details about it.
        </p>

        <Typography
          /* variant="body2" color="textSecondary" */ component="p"
          style={{ padding: '10px' }}
        >
          You can click on each request to show more details about the request
          such as the comments and deny/approve date.
        </Typography>
      </Paper>
      <br />
      {pendingRequests.length > 0 ? (
        <Paper variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Request ID</TableCell>
                <TableCell>Role Requested</TableCell>
                <TableCell>Date Requested</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {pendingRequests.map((request) => {
                const roleGroup =
                  request.role.group && request.role.subgroup
                    ? request.role.group + ' - ' + request.role.subgroup
                    : request.role.group
                return (
                  <TableRow
                    key={request.id}
                    onClick={() => history.push(`/pending/${request.id}`)}
                    hover
                  >
                    <TableCell>{request.id}</TableCell>
                    <TableCell>
                      {request.role.name}
                      {roleGroup ? ` (${roleGroup})` : null}
                    </TableCell>
                    <TableCell>{request.date}</TableCell>
                    <TableCell>{getStatus(request.status)}</TableCell>
                    <TableCell>
                      <CancelRequestButton id={request.id} refetch={refetch} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        <Alert severity="info">You currently have no pending requests.</Alert>
      )}
      <br />
    </div>
  )
}
