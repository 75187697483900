import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Requester from './requester/Requester'
import UserAgreement from './forms/UserAgreementDialog'
import Error from './Error'
import About from './About'
import Loading from './Loading'

export const query = gql`
  query agreement {
    auth {
      person {
        username
      }
    }
    agreement {
      userAgreementFlag
      userAgreementDate
      ferpaFlag
      ferpaDate
    }
  }
`

function MakeRequests() {
  const { loading, error, data } = useQuery(query, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Error error={error} />
  if (loading) return <Loading />

  const { agreement, auth } = data

  return (
    <div>
      {(auth?.person?.username && !agreement) ||
      agreement.userAgreementFlag === '0' ? (
        <UserAgreement />
      ) : null}
      <Requester />
    </div>
  )
}

export default function Home() {
  return (
    <div>
      <About />
      <br />
      <br />
      <MakeRequests />
      <br />
    </div>
  )
}
