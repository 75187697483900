import * as React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import Button from '@material-ui/core/Button'

import Error from '../Error'

const spoofUserMutation = gql`
  mutation spoofUser($username: String!) {
    spoofUser(username: $username) {
      username
    }
  }
`

export default function SpoofButton({ username }) {
  const [spoofUser, { error }] = useMutation(spoofUserMutation)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()

      await spoofUser({ variables: { username } })
      window.location.reload()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      {error && <Error error={error} />}
      <Button
        variant="outlined"
        type="submit"
        disabled={username === ''}
        onClick={handleSubmit}
      >
        Login as user
      </Button>
    </>
  )
}
