import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useParams } from 'react-router'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import EmailIcon from '@material-ui/icons/Email'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import Error from '../Error'
import Loading from '../Loading'

// import RequestWorkflow from './RequestWorkflow'

const query = gql`
  query ($requestId: ID!) {
    request(id: $requestId) {
      id
      role {
        name
        group
        subgroup
      }
      status
      date
      requester {
        name
        username
        csuId
        emailAddress
      }
      supervisor {
        name
      }
      supApprDate
      supDenyDate
      supComment
      dataSteward {
        person {
          name
        }
      }
      dsApprDate
      dsDenyDate
      dsComment
      vpdApprover {
        name
      }
      vpdApprDate
      vpdDenyDate
      vpdComment
      vpdDepartments
      grantDate
      revokeDate
    }
  }
`
const reRoute = (props, reqID) => {
  if (props.location.pathname === `/pending/${reqID}`) {
    props.history.push('/pending')
  } else if (props.location.pathname === `/history/${reqID}`) {
    props.history.push('/history')
  } else if (props.location.pathname === `/admin/request/${reqID}`) {
    props.history.push('/admin')
  } else {
    props.history.push('/')
  }
}

export default function RequestDetails(props) {
  const { id: requestId } = useParams()
  const { loading, error, data } = useQuery(query, {
    variables: { requestId },
  })

  if (error) return <Error error={error} />
  if (loading) return <Loading />

  const { request } = data

  if (!request)
    return (
      <Dialog
        open
        onClose={() => reRoute(props, requestId)}
        onBackdropClick={() => reRoute(props, requestId)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Request Details</DialogTitle>
        <Divider light />
        <DialogContent>
          <Error message={`Request Not Found: ${requestId}`} />
        </DialogContent>
        <Divider light />
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => reRoute(props, requestId)}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    )

  const departments = request.vpdDepartments
    ? request.vpdDepartments
        .split(',')
        .map((r) => (
          <Chip
            color="primary"
            key={r}
            label={r}
            style={{ marginRight: '2px' }}
          />
        ))
    : null

  return (
    <Dialog
      open
      onClose={() => reRoute(props, requestId)}
      onBackdropClick={() => reRoute(props, requestId)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Request Details</DialogTitle>
      <Divider light />
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>{request.role.name}</TableCell>
            </TableRow>
            {request.role.group && (
              <TableRow>
                <TableCell>Role Group</TableCell>
                <TableCell>{request.role.group}</TableCell>
              </TableRow>
            )}
            {request.role.subgroup && (
              <TableRow>
                <TableCell>Role Sub-group</TableCell>
                <TableCell>{request.role.subgroup}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Request ID</TableCell>
              <TableCell>{request.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Request Date</TableCell>
              <TableCell>{request.date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Requester Name</TableCell>
              <TableCell>{request.requester.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Requester E-mail</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  href={`mailto:${request.requester.emailAddress}`}
                  startIcon={<EmailIcon />}
                >
                  {request.requester.emailAddress}
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Requester Username</TableCell>
              <TableCell>{request.requester.username}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Requester CSU ID</TableCell>
              <TableCell>{request.requester.csuId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{request.status}</TableCell>
            </TableRow>
            {request.revokeDate && (
              <TableRow>
                <TableCell>Revoke Date</TableCell>
                <TableCell>{request.revokeDate}</TableCell>
              </TableRow>
            )}
            {request.grantDate && (
              <TableRow>
                <TableCell>Grant Date</TableCell>
                <TableCell>{request.grantDate}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Supervisor Name</TableCell>
              <TableCell>{request.supervisor.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data Steward Name</TableCell>
              <TableCell>
                {request.dataSteward && request.dataSteward.person.name}
              </TableCell>
            </TableRow>
            {request.supApprDate && (
              <TableRow>
                <TableCell>Supervisor Approve Date</TableCell>
                <TableCell>{request.supApprDate}</TableCell>
              </TableRow>
            )}
            {request.supDenyDate && (
              <TableRow>
                <TableCell>Supervisor Deny Date</TableCell>
                <TableCell>{request.supDenyDate}</TableCell>
              </TableRow>
            )}
            {request.supComment && (
              <TableRow>
                <TableCell>Supervisor Comment</TableCell>
                <TableCell>{request.supComment}</TableCell>
              </TableRow>
            )}
            {request.dsApprDate && (
              <TableRow>
                <TableCell>Data Steward Approve Date</TableCell>
                <TableCell>{request.dsApprDate}</TableCell>
              </TableRow>
            )}
            {request.dsDenyDate && (
              <TableRow>
                <TableCell>Data Steward Deny Date</TableCell>
                <TableCell>{request.dsDenyDate}</TableCell>
              </TableRow>
            )}
            {request.dsComment && (
              <TableRow>
                <TableCell>Data Steward Comment</TableCell>
                <TableCell>{request.dsComment}</TableCell>
              </TableRow>
            )}
            {request.vpdApprover && (
              <TableRow>
                <TableCell>HR Approver Name</TableCell>
                <TableCell>
                  {request.vpdApprover && request.vpdApprover.name}
                </TableCell>
              </TableRow>
            )}
            {request.vpdApprDate ? (
              <TableRow>
                <TableCell>HR Approver Approve Date</TableCell>
                <TableCell>{request.vpdApprDate}</TableCell>
              </TableRow>
            ) : null}
            {request.vpdDenyDate && (
              <TableRow>
                <TableCell>HR Approver Deny Date</TableCell>
                <TableCell>{request.vpdDenyDate}</TableCell>
              </TableRow>
            )}
            {request.vpdComment && (
              <TableRow>
                <TableCell>HR Approver Comment</TableCell>
                <TableCell>{request.vpdComment}</TableCell>
              </TableRow>
            )}
            {request.vpdDepartments ? (
              <TableRow>
                <TableCell>Department Numbers Selected</TableCell>
                <TableCell>{departments}</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        {/* <RequestWorkflow /> */}
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => reRoute(props, requestId)}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  )
}
