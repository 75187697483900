import * as React from 'react'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Divider from '@material-ui/core/Divider'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import HistoryByDataSteward from './HistoryByDataSteward'
import HistoryBySupervisor from './HistoryBySupervisor'
import HistoryByRequester from './HistoryByRequester'
import HistoryByVpdApprover from './HistoryByVpdApprover'

export default function HistoryDetails({ role }) {
  const [filter, setFilter] = React.useState(false)

  let historyComponent
  let toggle = null

  // Toggling to show only request histories for people under their current supervision or stewardship
  if (role === 'Data Steward') {
    historyComponent = <HistoryByDataSteward filter={filter} />
    toggle = (
      <div style={{ textAlign: 'right' }}>
        <span>
          Show only requesters that are currently under my stewardship
        </span>
        <Switch
          onChange={(event) => setFilter(event.target.checked)}
          color="default"
        />
      </div>
    )
  } else if (role === 'Supervisor') {
    historyComponent = <HistoryBySupervisor filter={filter} />
    toggle = (
      <div style={{ textAlign: 'right' }}>
        <span>
          Show only requesters that are currently under my supervision
        </span>
        <Switch
          onChange={(event) => setFilter(event.target.checked)}
          color="default"
        />
      </div>
    )
  } else if (role === 'HR Approver') {
    historyComponent = <HistoryByVpdApprover />
  } else {
    historyComponent = <HistoryByRequester />
  }

  return (
    <Accordion variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">History as {role}</Typography>
      </AccordionSummary>
      <Divider light />
      {toggle && (
        <div>
          <AccordionDetails>{toggle || ''}</AccordionDetails>
          <Divider />
        </div>
      )}
      {historyComponent}
    </Accordion>
  )
}
