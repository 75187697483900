import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import Error from '../Error'
import Warning from './Warning'
import RolesTable from './RolesTable'
import CopyRolesButton from './CopyRolesButton'
import SubmitButton from './SubmitButton'
import SubmittedDialog from './SubmittedDialog'
import Loading from '../Loading'
import Feature from '../../Feature'

export const query = gql`
  query RequesterQuery {
    auth {
      person {
        supervisor {
          name
        }
        dataSteward {
          person {
            name
          }
        }
      }
    }
    agreement {
      ferpaDate
      ferpaFlag
    }
    roles {
      id
      group
      subgroup
      name
      desc
      granted
    }
    availableRoles {
      id
      group
      subgroup
      name
      desc
      granted
    }
    roleGroups {
      name
      description
      subgroups {
        name
        description
      }
    }
    requests {
      date
      id
      isPending
      status
      role {
        name
      }
    }
  }
`

const getFilteredRoles = ({ data }) => {
  const pendingRequests = (data.requests || []).filter((r) => r.isPending)
  const filteredRoles = (data.roles || []).filter(
    (role) => !pendingRequests.map((r) => r.role.name).includes(role.name)
  )

  return filteredRoles
}

export default function Requester() {
  const [selectedRoles, setSelectedRoles] = React.useState([])
  const [isSubmittedDialogOpen, setIsSubmittedDialogOpen] =
    React.useState(false)
  const { data, error, loading } = useQuery(query)

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  const grantedRoles = getFilteredRoles({ data }).filter((r) => r.granted)
  const { agreement, auth, availableRoles, roleGroups } = data

  if (!auth.person) {
    return <Error message="Unexpected Error" />
  }

  if (!auth.person.supervisor) {
    return (
      <Warning
        title={'No Supervisor Defined'}
        message={
          'Since you do not have a supervisor defined, we have disabled your ability to make requests.'
        }
      />
    )
  }

  if (!auth.person.dataSteward) {
    return (
      <Warning
        title={'No Data Steward has been Established for your Department'}
        message={
          'Since your department has not established a data steward, we have disabled your ability to make requests.'
        }
      />
    )
  }

  return (
    <>
      <Typography gutterBottom variant="h5" component="h2">
        Make Requests
        <Feature name="copyRoles">
          <CopyRolesButton
            availableRoles={availableRoles}
            setSelectedRoles={setSelectedRoles}
          />
        </Feature>
      </Typography>

      {grantedRoles.length > 0 && (
        <div style={{ paddingBottom: 10 }}>
          You already have access to the following roles:&nbsp;
          {grantedRoles.map((r) => (
            <Chip
              color="primary"
              key={r.id}
              label={r.name}
              style={{ marginRight: '2px' }}
            />
          ))}
        </div>
      )}

      {availableRoles.length > 0 &&
      auth.person.dataSteward &&
      auth.person.supervisor ? (
        <>
          <RolesTable
            selectedRoles={selectedRoles}
            availableRoles={availableRoles}
            roleGroups={roleGroups}
            setSelectedRoles={setSelectedRoles}
          />
          <br />
          <SubmitButton
            setIsSubmittedDialogOpen={setIsSubmittedDialogOpen}
            selectedRoles={selectedRoles}
            agreement={agreement}
            setSelectedRoles={setSelectedRoles}
          />
        </>
      ) : (
        'No available roles to be requested'
      )}

      {isSubmittedDialogOpen && (
        <SubmittedDialog
          onCloseDialog={() => setIsSubmittedDialogOpen(false)}
          roleNames={selectedRoles.map((i) => i.name)}
          setSelectedRoles={setSelectedRoles}
        />
      )}
    </>
  )
}
