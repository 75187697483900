import * as React from 'react'
import gql from 'graphql-tag'
import { ApolloProvider } from '@apollo/client'
import { useQuery } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'

import client from './client'
import ErrorBoundary from './ErrorBoundary'
import Login from './components/auth/Login'
import Routes from './Routes'
import TopMenu from './components/TopMenu'
import SideMenu from './components/SideMenu'
import Error from './components/Error'
import Loading from './components/Loading'

import MaintenanceMessage from './MaintenanceMessage'

import AppLayout from './AppLayout'

const query = gql`
  query AuthInfoQuery {
    auth {
      username
      institution {
        appBarLogoUrl
        primaryColor
        secondaryColor
      }
    }
  }
`

function Layout() {
  const { loading, error, data } = useQuery(query)

  if (error) {
    return <Error error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const main = data.auth ? <Routes /> : <Login />

  const appBarLogoUrl =
    data.auth?.institution?.appBarLogoUrl || '/logo-appbar-colostate.svg'
  const primaryColor = data.auth?.institution?.primaryColor || '#1E4D2B'
  const secondaryColor = data.auth?.institution?.secondaryColor || '#999999'

  const theme = createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  })

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <AppLayout
          headerTitle={'ODS User Data Access Request'}
          main={
            <>
              <MaintenanceMessage />
              {main}
            </>
          }
          topMenu={<TopMenu />}
          sideMenu={<SideMenu />}
          appBarLogoUrl={appBarLogoUrl}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        ></AppLayout>
      </SnackbarProvider>
    </MuiThemeProvider>
  )
}

export default function App() {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </ApolloProvider>
    </ErrorBoundary>
  )
}
