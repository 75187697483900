import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import ResetPassword from './ResetPassword'
import Spoof from '../spoofing/Spoof'
import SpoofButton from '../spoofing/SpoofButton'
import Error from '../Error'
import Feature from '../../Feature'
import Loading from '../Loading'
import SignOutButton from './SignOutButton'

const authQuery = gql`
  query AuthInfoQuery {
    auth {
      admin
      username
      csuId
      eppn
      institution {
        name
      }
      person {
        name
        emailAddress
        employeeDepartmentNumber
        supervisor {
          name
          username
        }
        dataSteward {
          person {
            name
            username
          }
        }
      }
    }
  }
`

export default function MyAccount() {
  const { loading, error, data } = useQuery(authQuery)

  if (error) return <Error error={error} />
  if (loading) return <Loading />

  return (
    <div>
      <Paper elevation={0} variant="outlined">
        <div style={{ padding: '10px' }}>
          <div style={{ float: 'right' }}>
            <SignOutButton>Sign Out</SignOutButton>
          </div>
          <Typography variant="h5">My Account</Typography>
        </div>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>You are logged in as:</TableCell>
              <TableCell>
                <strong>{data.auth.person.name || ''}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Your Username:</TableCell>
              <TableCell>
                <strong>{data.auth.username}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Your CSU ID:</TableCell>
              <TableCell>
                <strong>{data.auth.csuId}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Your E-mail:</TableCell>
              <TableCell>
                <strong>{data.auth.person.emailAddress}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Your Institution:</TableCell>
              <TableCell>
                <strong>{data.auth?.institution?.name || ''}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Your Department:</TableCell>
              <TableCell>
                <strong>
                  {data.auth.person?.employeeDepartmentNumber || ''}
                </strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Your Supervisor:</TableCell>
              <TableCell>
                <strong>{data.auth.person?.supervisor?.name}</strong>
                <Feature name="spoofUser">
                  <>
                    {data.auth.admin && data.auth.person?.supervisor?.username && (
                      <span style={{ float: 'right' }}>
                        <SpoofButton
                          username={data.auth.person.supervisor.username}
                        />
                      </span>
                    )}
                  </>
                </Feature>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Your Data-Steward:</TableCell>
              <TableCell>
                <strong>{data.auth.person?.dataSteward?.person?.name}</strong>
                <Feature name="spoofUser">
                  <>
                    {data.auth.admin &&
                      data.auth?.person?.dataSteward?.person?.username && (
                        <span style={{ float: 'right' }}>
                          <SpoofButton
                            username={
                              data.auth.person.dataSteward.person.username
                            }
                          />
                        </span>
                      )}
                  </>
                </Feature>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <br />
      <Feature name="resetPassword">
        <>
          <ResetPassword />
          <br />
        </>
      </Feature>
      <Feature name="spoofUser">
        {data.auth.admin && <Spoof onCloseDialog={() => {}} />}
      </Feature>
    </div>
  )
}
