import * as React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'

import Loading from '../Loading'
import Error from '../Error'
import { query } from '../requester/Requester'

const updateFerpaMutation = gql`
  mutation($action: Boolean!) {
    updateFerpa(action: $action) {
      ferpaFlag
      ferpaDate
    }
  }
`

export default function FerpaAgreementDialog({
  createRequests,
  setIsSubmittedDialogOpen,
  closeDialog,
  departmentNumbers,
  setIsVpdDialogOpen,
}) {
  const [submitting, setSubmitting] = React.useState(false)
  const [checked, setChecked] = React.useState(false)

  const [updateFerpa, { error }] = useMutation(updateFerpaMutation, {
    update(cache, { data: { updateFerpa } }) {
      const requesterQuery = cache.readQuery({ query })
      
      cache.writeQuery({
        query,
        data: {
          ...requesterQuery,
          agreement: {
            ...updateFerpa,
          },
        },
      })
    },
  })

  const closeBothDialog = () => {
    if (typeof setIsVpdDialogOpen === 'function') {
      setIsVpdDialogOpen(false)
      closeDialog()
    } else {
      closeDialog()
    }
  }

  return (
    <Dialog
      open={true}
      onClose={closeBothDialog}
      onBackdropClick={closeBothDialog}
    >
      <DialogTitle>FERPA</DialogTitle>
      <Divider light />
      <DialogContent>
        <div>
          <p>
            In accordance with The Family Educational Rights and Privacy Act
            (FERPA),
          </p>
          <p>
            20 U.S.C. 1232g, and implementing regulations, 34 C.F.R. Part 99,
            section 99.31(a)(1), you are hereby notified that all information
            obtained through this website is provided by the student upon the
            condition that you will not disclose the information to any other
            party, nor permit any other party access to this record, without the
            prior consent of the student.
          </p>
          <p>(You will be asked to respond to this annually.)</p>
          <p>
            Please check this box to indicate you have read the above notice and
            agree to it. You must check the box and click submit to continue.
          </p>
          <Divider light />
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(event, checked) => setChecked(checked)}
          />
          <span>I agree</span>
          <Divider light />
          <br />
          {error && <Error error={error} />}
          {submitting && <Loading />}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={submitting}
          onClick={() => closeBothDialog()}
        >
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!checked || submitting}
          onClick={async () => {
            try {
              setSubmitting(true)
              await updateFerpa({
                variables: { action: checked },
              })

              if (typeof createRequests === 'function') {
                await createRequests(departmentNumbers)
                closeDialog()
                setIsSubmittedDialogOpen(true)
              }

              closeDialog()
            } catch (err) {
              setSubmitting(false)
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
