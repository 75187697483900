import * as React from 'react'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

export default function About() {
  return (
    <Paper elevation={0} variant="outlined">
      <Typography variant="h5" component="h2" style={{ padding: '10px' }}>
        About
      </Typography>
      <Divider />
      <Typography
        variant="body2"
        /* color="textSecondary" */ component="p"
        style={{ padding: '10px' }}
      >
        The ODS Data Access Request application automates the approval process
        for requesting and granting access to data in the ODS for Student, Human
        Resources, Finance, Accounts Receivable, General Directory, Sponsored
        Research, Facilities, CSU Foundation, and Graduate Student data. <br />
        <br />
        Once a request is approved by a supervisor and granted by the department
        data steward, the requester will have access to all related information
        for their department, not just for themselves. <br />
        <br />
        If you are trying to access your own personal HR information, this is
        NOT the correct application. That information can be viewed from the
        "Human Resources" menu item on the{' '}
        <a
          href="https://aar.is.colostate.edu"
          target="_blank"
          rel="noopener noreferrer"
        >
          Administrative Applications and Resources (AAR)
        </a>{' '}
        page .
      </Typography>
    </Paper>
  )
}
