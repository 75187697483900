import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import MUIDataTable from 'mui-datatables'

import Error from '../Error'
import Loading from '../Loading'

const columns = [
  { name: 'Request ID', options: { filter: false, sort: false } },
  { name: 'Requester', options: { filter: false, sort: true } },
  { name: 'Date', options: { filter: false, sort: true } },
  { name: 'Role Name', options: { filter: true, sort: true } },
  { name: 'Approval Status', options: { filter: true, sort: true } },
  { name: 'Request Status', options: { filter: true, sort: true } },
]

export const query = gql`
  query getAllRequests {
    roles {
      id
      name
      orclName
    }
    getAllRequests {
      id
      uaCsuId
      date
      isPending
      status
      csuRoleId
    }
  }
`

export default function AdminRequests() {
  let history = useHistory()

  const { loading, error, data } = useQuery(query)

  if (error) return <Error error={error} />
  if (loading) return <Loading />

  const requests = data.getAllRequests
  const roles = data.roles

  return (
    <Paper>
      <Typography variant="h5" style={{ padding: '10px' }}>
        Admin
      </Typography>
      <Divider />
      <MUIDataTable
        data={requests
          .map((req) => {
            const role = roles.find((role) => role.id === req.csuRoleId)

            return [
              req.id,
              req.uaCsuId,
              req.date,
              role?.name,
              req.status,
              req.isPending ? 'Pending' : 'Complete',
            ]
          })
          .reverse()}
        columns={columns}
        options={{
          filterType: 'dropdown',
          selectableRows: 'none',
          download: true,
          downloadOptions: {
            filename: `DAR-Admin-Requests.csv`,
            separator: ',',
          },
          print: false,
          elevation: 0,
          rowHover: true,
          onRowClick: (rowIndex) =>
            history.push(`/admin/request/${rowIndex[0]}`),
        }}
      />
    </Paper>
  )
}
