import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useHistory, useLocation } from 'react-router-dom'

import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'

import Loading from './Loading'
import { query as approvalsQuery } from './approval/Approvals'

const authInfoQuery = gql`
  query AuthInfoQuery {
    auth {
      admin
      person {
        name
      }
    }
    # usersGrantedByMe {
    #   name
    #   roles {
    #     name
    #     id
    #   }
    # }
  }
`

export default function TopMenu() {
  const history = useHistory()
  const location = useLocation()

  const { loading, error, data } = useQuery(authInfoQuery, {
    fetchPolicy: 'network-only',
  })

  const {
    loading: loading2,
    error: error2,
    data: data2,
  } = useQuery(approvalsQuery, {
    fetchPolicy: 'network-only',
  })

  if (error) return null
  if (loading || loading2) return <Loading />

  const {
    auth,
    //, usersGrantedByMe
  } = data

  if (!auth) {
    return null
  }

  let approvalsCount = null

  if (!error2) {
    const {
      requestsForSupervisor,
      requestsForDataSteward,
      requestsForVpdApprover,
    } = data2
    approvalsCount =
      requestsForSupervisor.filter(
        (request) => request.awaiting === 'SUPERVISOR'
      ).length +
      requestsForDataSteward.filter(
        (request) => request.awaiting === 'DATASTEWARD'
      ).length +
      requestsForVpdApprover.filter(
        (request) => request.awaiting === 'VPDACCESS'
      ).length
  }

  return (
    <div style={{ padding: '10px' }}>
      <Button
        color="primary"
        variant={location.pathname === '/' ? 'contained' : 'outlined'}
        onClick={() => history.push('/')}
      >
        Home
      </Button>{' '}
      <Button
        color="primary"
        variant={location.pathname === '/pending' ? 'contained' : 'outlined'}
        onClick={() => history.push('/pending')}
      >
        Pending
      </Button>{' '}
      <Badge badgeContent={approvalsCount || 0} color="error">
        <Button
          color="primary"
          variant={
            location.pathname === '/approvals' ? 'contained' : 'outlined'
          }
          onClick={() => history.push('/approvals')}
        >
          Approvals
        </Button>
      </Badge>{' '}
      <Button
        color="primary"
        variant={location.pathname === '/history' ? 'contained' : 'outlined'}
        onClick={() => history.push('/history')}
      >
        History
      </Button>{' '}
      {/* {usersGrantedByMe.length > 0 && ( */}
      <Button
        color="primary"
        variant={location.pathname === '/report' ? 'contained' : 'outlined'}
        onClick={() => history.push('/report')}
      >
        Report
      </Button>
      {/* )} */}{' '}
      <Button
        color="primary"
        variant={location.pathname === '/help' ? 'contained' : 'outlined'}
        onClick={() => history.push('/help')}
      >
        Help
      </Button>{' '}
      {auth.admin && (
        <Button
          color="primary"
          variant={
            location.pathname.startsWith('/admin') ? 'contained' : 'outlined'
          }
          onClick={() => history.push('/admin')}
        >
          Admin
        </Button>
      )}{' '}
      <Button
        color="primary"
        variant={location.pathname === '/account' ? 'contained' : 'outlined'}
        onClick={() => history.push('/account')}
      >
        My Account
      </Button>
    </div>
  )
}
